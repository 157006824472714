import pegboard_belakang_1 from '../../assets/pegboard/belakang/a.png'
import pegboard_belakang_2 from '../../assets/pegboard/belakang/b.png'
import pegboard_belakang_3 from '../../assets/pegboard/belakang/c.png'
import pegboard_belakang_4 from '../../assets/pegboard/belakang/d.png'
import pegboard_belakang_5 from '../../assets/pegboard/belakang/e.png'
import pegboard_belakang_6 from '../../assets/pegboard/belakang/f.png'
import pegboard_belakang_7 from '../../assets/pegboard/belakang/g.png'

import pegboard_depan_1 from '../../assets/pegboard/depan/a.png'
import pegboard_depan_2 from '../../assets/pegboard/depan/b.png'
import pegboard_depan_3 from '../../assets/pegboard/depan/c.png'
import pegboard_depan_4 from '../../assets/pegboard/depan/d.png'
import pegboard_depan_5 from '../../assets/pegboard/depan/e.png'
import pegboard_depan_6 from '../../assets/pegboard/depan/f.png'
import pegboard_depan_7 from '../../assets/pegboard/depan/g.png'

import pegboard_samping_kanan_1 from '../../assets/pegboard/kanan/a.png'
import pegboard_samping_kanan_2 from '../../assets/pegboard/kanan/b.png'
import pegboard_samping_kanan_3 from '../../assets/pegboard/kanan/c.png'
import pegboard_samping_kanan_4 from '../../assets/pegboard/kanan/d.png'
import pegboard_samping_kanan_5 from '../../assets/pegboard/kanan/e.png'
import pegboard_samping_kanan_6 from '../../assets/pegboard/kanan/f.png'
import pegboard_samping_kanan_7 from '../../assets/pegboard/kanan/g.png'

import pegboard_samping_kiri_1 from '../../assets/pegboard/kiri/a.png'
import pegboard_samping_kiri_2 from '../../assets/pegboard/kiri/b.png'
import pegboard_samping_kiri_3 from '../../assets/pegboard/kiri/c.png'
import pegboard_samping_kiri_4 from '../../assets/pegboard/kiri/d.png'
import pegboard_samping_kiri_5 from '../../assets/pegboard/kiri/e.png'
import pegboard_samping_kiri_6 from '../../assets/pegboard/kiri/f.png'
import pegboard_samping_kiri_7 from '../../assets/pegboard/kiri/g.png'

export default class PegboardData{
  name = "PEGBOARD | RAK DINDING TEMPEL TEMBOK"
  description = "Pegboard papan dinding didesain full menggunakan bahan daur ulang sampah plastik. Dimanfaatkan untuk menyimpan berbagai barang2 kecil, sebagai dekorasi utk memperindah ruangan kalian, dan hemat tempat karena menempel di dinding. Bisa diletakan ruang manapun, tanpa membuatnya sempit. Bisa di gunakan untuk menyimpan berbagai koleksi aksesoris kerja atau aksesoris kesayangan anda, seperti buku, kamera, dll"
  size = "Ukuran : Pegboard : 39x39x1 cm</br>Berat : 1 Kg</br>Material : Daur Ulang Sampah plastik</br></br>Free:</br>Shelf uk 15 x 7 cm</br>Hook 5 pcs"
  price = "Rp110.000,00"

  arr = [
    [
      pegboard_belakang_1,
      pegboard_depan_1,
      pegboard_samping_kanan_1,
      pegboard_samping_kiri_1,
    ],
    [
      pegboard_belakang_2,
      pegboard_depan_2,
      pegboard_samping_kanan_2,
      pegboard_samping_kiri_2,
    ],
    [
      pegboard_belakang_3,
      pegboard_depan_3,
      pegboard_samping_kanan_3,
      pegboard_samping_kiri_3,
    ],
    [
      pegboard_belakang_4,
      pegboard_depan_4,
      pegboard_samping_kanan_4,
      pegboard_samping_kiri_4,
    ],
    [
      pegboard_belakang_5,
      pegboard_depan_5,
      pegboard_samping_kanan_5,
      pegboard_samping_kiri_5,
    ],
    [
      pegboard_belakang_6,
      pegboard_depan_6,
      pegboard_samping_kanan_6,
      pegboard_samping_kiri_6,
    ],
    [
      pegboard_belakang_7,
      pegboard_depan_7,
      pegboard_samping_kanan_7,
      pegboard_samping_kiri_7,
    ],
  ]

}
