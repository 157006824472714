export default {
  app_name: "Commerce Web",
  notice: 'Notice',
  please_wait: 'Please Wait',
  enter_email: 'Enter Email',
  enter_password: 'Enter Password',
  login: 'Login',
  email_empty: 'Email is Empty',
  password_empty: 'Password is Empty',
  front: 'Depan',
  rear: 'Belakang',
  left: 'Samping Kiri',
  right: 'Samping Kanan',
  side: 'Samping',
}
