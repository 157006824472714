import rak_makeup_belakang_1 from '../../assets/rak_makeup/belakang/a.png'
import rak_makeup_belakang_2 from '../../assets/rak_makeup/belakang/b.png'
import rak_makeup_belakang_3 from '../../assets/rak_makeup/belakang/c.png'
import rak_makeup_belakang_4 from '../../assets/rak_makeup/belakang/d.png'
import rak_makeup_belakang_5 from '../../assets/rak_makeup/belakang/e.png'
import rak_makeup_belakang_6 from '../../assets/rak_makeup/belakang/f.png'
import rak_makeup_belakang_7 from '../../assets/rak_makeup/belakang/g.png'

import rak_makeup_depan_1 from '../../assets/rak_makeup/depan/a.png'
import rak_makeup_depan_2 from '../../assets/rak_makeup/depan/b.png'
import rak_makeup_depan_3 from '../../assets/rak_makeup/depan/c.png'
import rak_makeup_depan_4 from '../../assets/rak_makeup/depan/d.png'
import rak_makeup_depan_5 from '../../assets/rak_makeup/depan/e.png'
import rak_makeup_depan_6 from '../../assets/rak_makeup/depan/f.png'
import rak_makeup_depan_7 from '../../assets/rak_makeup/depan/g.png'

import rak_makeup_samping_kanan_1 from '../../assets/rak_makeup/kanan/a.png'
import rak_makeup_samping_kanan_2 from '../../assets/rak_makeup/kanan/b.png'
import rak_makeup_samping_kanan_3 from '../../assets/rak_makeup/kanan/c.png'
import rak_makeup_samping_kanan_4 from '../../assets/rak_makeup/kanan/d.png'
import rak_makeup_samping_kanan_5 from '../../assets/rak_makeup/kanan/e.png'
import rak_makeup_samping_kanan_6 from '../../assets/rak_makeup/kanan/f.png'
import rak_makeup_samping_kanan_7 from '../../assets/rak_makeup/kanan/g.png'

import rak_makeup_samping_kiri_1 from '../../assets/rak_makeup/kiri/a.png'
import rak_makeup_samping_kiri_2 from '../../assets/rak_makeup/kiri/b.png'
import rak_makeup_samping_kiri_3 from '../../assets/rak_makeup/kiri/c.png'
import rak_makeup_samping_kiri_4 from '../../assets/rak_makeup/kiri/d.png'
import rak_makeup_samping_kiri_5 from '../../assets/rak_makeup/kiri/e.png'
import rak_makeup_samping_kiri_6 from '../../assets/rak_makeup/kiri/f.png'
import rak_makeup_samping_kiri_7 from '../../assets/rak_makeup/kiri/g.png'

export default class RakMakeupData{
  name = "Makeup Organizer"
  description = "Make up organizer, Rak penyimpanan Kuas/brush make up + alat make up lainnya digunakan untuk penyimpanan kosmetik, perhiasan dan atal make up atau display. Dapat mempercantik dan merapikan tampilan tempat penyimpanan kosmetik di ruang anda"
  size = "Ukuran : (PxLxT) : 30 x 19,5 x 10 cm </br>Berat : 1 Kg</br>Material : Daur Ulang Sampah plastik"
  price = "Rp193.500,00"

  arr = [
    [
      rak_makeup_belakang_1,
      rak_makeup_depan_1,
      rak_makeup_samping_kanan_1,
      rak_makeup_samping_kiri_1,
    ],
    [
      rak_makeup_belakang_2,
      rak_makeup_depan_2,
      rak_makeup_samping_kanan_2,
      rak_makeup_samping_kiri_2,
    ],
    [
      rak_makeup_belakang_3,
      rak_makeup_depan_3,
      rak_makeup_samping_kanan_3,
      rak_makeup_samping_kiri_3,
    ],
    [
      rak_makeup_belakang_4,
      rak_makeup_depan_4,
      rak_makeup_samping_kanan_4,
      rak_makeup_samping_kiri_4,
    ],
    [
      rak_makeup_belakang_5,
      rak_makeup_depan_5,
      rak_makeup_samping_kanan_5,
      rak_makeup_samping_kiri_5,
    ],
    [
      rak_makeup_belakang_6,
      rak_makeup_depan_6,
      rak_makeup_samping_kanan_6,
      rak_makeup_samping_kiri_6,
    ],
    [
      rak_makeup_belakang_7,
      rak_makeup_depan_7,
      rak_makeup_samping_kanan_7,
      rak_makeup_samping_kiri_7,
    ],
  ]

}
