import axios from 'axios'
import {NotificationManager} from 'react-notifications'

import I18n from "./i18n"

export default class Base{
  host = 'https://pos-commerce.quantumtri.com'
  is_loading = false
  search_wait_time = 1000
  locale_string = 'id-ID'
  i18n = I18n

  constructor(){
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.timeout = 600000
    // this.redirect_to_login()
  }

  async request(url, method = "get", data = {}, onUploadProgress = response => {}, use_image = false){
    axios.defaults.headers.common['Authorization'] = await window.localStorage.getItem('token')

    try{
      if(!use_image)
        window.$('#pleaseWait').modal('show')
      else
        window.$('#pleaseWaitWithProgress').modal('show')
      var response
      if(method === 'get')
        response = await axios.get(url)
      else if(method === 'post')
        response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
          onUploadProgress
        })
      else if(method === 'put')
        response = await axios.put(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
          onUploadProgress
        })
      else if(method === 'delete')
        response = await axios.delete(url)
      setTimeout(() => {
        window.$('#pleaseWait').modal('hide')
        window.$('#pleaseWaitWithProgress').modal('hide')
      }, 500)

      if(response.data.status === 'success')
        return response.data

      this.notify_user(response.data.message, 'error')
      if(response.data.error_data === 'token_invalid' || response.data.error_data === 'token_revoked' || response.data.error_data === 'token_not_found'){
        window.localStorage.removeItem('token')
        window.location.href = "/auth/login"
      }
    } catch (e) {
      setTimeout(() => {
        window.$('#pleaseWait').modal('hide')
        window.$('#pleaseWaitWithProgress').modal('hide')
      }, 500)
      this.notify_user(e.message, 'error')
    }
  }

  async redirect_to_login(){
    var token = await window.localStorage.getItem('token')

    if((token === '' || token == null) && window.location.pathname !== '/auth/login')
      window.location.href = '/auth/login'
  }

  getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  to_currency_format(data, set_data = null){
    var value
    if(data != '')
      value = parseFloat(data.replace(/\./g,''))
    else
      value = 0
    if(set_data != null)
      set_data(value.toLocaleString(this.locale_string))
    else
      return value.toLocaleString(this.locale_string)
  }

  notify_user(message, type = 'info'){
    if(type == 'error')
      NotificationManager.error(message)
    else
      NotificationManager.info(message)
  }

  add_array(arr, set_state, data = {}){
    var temp = [...arr]
    temp.push(data)
    set_state(temp)
  }

  update_array(arr, set_state, data = {}, index = 0){
    var temp = [...arr]
    temp[index] = data
    set_state(temp)
  }

  remove_array(arr, set_state, index = 0){
    var temp = [...arr]
    temp.splice(index, 1)
    set_state(temp)
  }

  update_object(data, set_state, variable = null, key = ''){
    var temp = JSON.parse(JSON.stringify(data))
    temp[key] = variable
    set_state(temp)
  }

  async get_file(image){
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    return file
  }

  limit_data_length(data, set_data = null, length = 100){
    if(data != null){
      if(set_data != null)
        set_data(data.substring(0, length))
      return data.substring(0, length)
    }

    if(set_data != null)
      set_data('')
    return ''
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
