import meja_lipat_hitam_belakang_1 from '../../assets/meja_lipat/kaki_hitam/Belakang/a.png'
import meja_lipat_hitam_belakang_2 from '../../assets/meja_lipat/kaki_hitam/Belakang/b.png'
import meja_lipat_hitam_belakang_3 from '../../assets/meja_lipat/kaki_hitam/Belakang/c.png'
import meja_lipat_hitam_belakang_4 from '../../assets/meja_lipat/kaki_hitam/Belakang/d.png'
import meja_lipat_hitam_belakang_5 from '../../assets/meja_lipat/kaki_hitam/Belakang/e.png'
import meja_lipat_hitam_belakang_6 from '../../assets/meja_lipat/kaki_hitam/Belakang/f.png'
import meja_lipat_hitam_belakang_7 from '../../assets/meja_lipat/kaki_hitam/Belakang/g.png'

import meja_lipat_hitam_depan_1 from '../../assets/meja_lipat/kaki_hitam/Depan/a.png'
import meja_lipat_hitam_depan_2 from '../../assets/meja_lipat/kaki_hitam/Depan/b.png'
import meja_lipat_hitam_depan_3 from '../../assets/meja_lipat/kaki_hitam/Depan/c.png'
import meja_lipat_hitam_depan_4 from '../../assets/meja_lipat/kaki_hitam/Depan/d.png'
import meja_lipat_hitam_depan_5 from '../../assets/meja_lipat/kaki_hitam/Depan/e.png'
import meja_lipat_hitam_depan_6 from '../../assets/meja_lipat/kaki_hitam/Depan/f.png'
import meja_lipat_hitam_depan_7 from '../../assets/meja_lipat/kaki_hitam/Depan/g.png'

import meja_lipat_hitam_samping_kanan_1 from '../../assets/meja_lipat/kaki_hitam/Kanan/a.png'
import meja_lipat_hitam_samping_kanan_2 from '../../assets/meja_lipat/kaki_hitam/Kanan/b.png'
import meja_lipat_hitam_samping_kanan_3 from '../../assets/meja_lipat/kaki_hitam/Kanan/c.png'
import meja_lipat_hitam_samping_kanan_4 from '../../assets/meja_lipat/kaki_hitam/Kanan/d.png'
import meja_lipat_hitam_samping_kanan_5 from '../../assets/meja_lipat/kaki_hitam/Kanan/e.png'
import meja_lipat_hitam_samping_kanan_6 from '../../assets/meja_lipat/kaki_hitam/Kanan/f.png'
import meja_lipat_hitam_samping_kanan_7 from '../../assets/meja_lipat/kaki_hitam/Kanan/g.png'

import meja_lipat_hitam_samping_kiri_1 from '../../assets/meja_lipat/kaki_hitam/Kiri/a.png'
import meja_lipat_hitam_samping_kiri_2 from '../../assets/meja_lipat/kaki_hitam/Kiri/b.png'
import meja_lipat_hitam_samping_kiri_3 from '../../assets/meja_lipat/kaki_hitam/Kiri/c.png'
import meja_lipat_hitam_samping_kiri_4 from '../../assets/meja_lipat/kaki_hitam/Kiri/d.png'
import meja_lipat_hitam_samping_kiri_5 from '../../assets/meja_lipat/kaki_hitam/Kiri/e.png'
import meja_lipat_hitam_samping_kiri_6 from '../../assets/meja_lipat/kaki_hitam/Kiri/f.png'
import meja_lipat_hitam_samping_kiri_7 from '../../assets/meja_lipat/kaki_hitam/Kiri/g.png'

import meja_lipat_putih_belakang_1 from '../../assets/meja_lipat/kaki_putih/Belakang/a.png'
import meja_lipat_putih_belakang_2 from '../../assets/meja_lipat/kaki_putih/Belakang/b.png'
import meja_lipat_putih_belakang_3 from '../../assets/meja_lipat/kaki_putih/Belakang/c.png'
import meja_lipat_putih_belakang_4 from '../../assets/meja_lipat/kaki_putih/Belakang/d.png'
import meja_lipat_putih_belakang_5 from '../../assets/meja_lipat/kaki_putih/Belakang/e.png'
import meja_lipat_putih_belakang_6 from '../../assets/meja_lipat/kaki_putih/Belakang/f.png'
import meja_lipat_putih_belakang_7 from '../../assets/meja_lipat/kaki_putih/Belakang/g.png'

import meja_lipat_putih_depan_1 from '../../assets/meja_lipat/kaki_putih/Depan/a.png'
import meja_lipat_putih_depan_2 from '../../assets/meja_lipat/kaki_putih/Depan/b.png'
import meja_lipat_putih_depan_3 from '../../assets/meja_lipat/kaki_putih/Depan/c.png'
import meja_lipat_putih_depan_4 from '../../assets/meja_lipat/kaki_putih/Depan/d.png'
import meja_lipat_putih_depan_5 from '../../assets/meja_lipat/kaki_putih/Depan/e.png'
import meja_lipat_putih_depan_6 from '../../assets/meja_lipat/kaki_putih/Depan/f.png'
import meja_lipat_putih_depan_7 from '../../assets/meja_lipat/kaki_putih/Depan/g.png'

import meja_lipat_putih_samping_kanan_1 from '../../assets/meja_lipat/kaki_putih/Kanan/a.png'
import meja_lipat_putih_samping_kanan_2 from '../../assets/meja_lipat/kaki_putih/Kanan/b.png'
import meja_lipat_putih_samping_kanan_3 from '../../assets/meja_lipat/kaki_putih/Kanan/c.png'
import meja_lipat_putih_samping_kanan_4 from '../../assets/meja_lipat/kaki_putih/Kanan/d.png'
import meja_lipat_putih_samping_kanan_5 from '../../assets/meja_lipat/kaki_putih/Kanan/e.png'
import meja_lipat_putih_samping_kanan_6 from '../../assets/meja_lipat/kaki_putih/Kanan/f.png'
import meja_lipat_putih_samping_kanan_7 from '../../assets/meja_lipat/kaki_putih/Kanan/g.png'

import meja_lipat_putih_samping_kiri_1 from '../../assets/meja_lipat/kaki_putih/Kiri/a.png'
import meja_lipat_putih_samping_kiri_2 from '../../assets/meja_lipat/kaki_putih/Kiri/b.png'
import meja_lipat_putih_samping_kiri_3 from '../../assets/meja_lipat/kaki_putih/Kiri/c.png'
import meja_lipat_putih_samping_kiri_4 from '../../assets/meja_lipat/kaki_putih/Kiri/d.png'
import meja_lipat_putih_samping_kiri_5 from '../../assets/meja_lipat/kaki_putih/Kiri/e.png'
import meja_lipat_putih_samping_kiri_6 from '../../assets/meja_lipat/kaki_putih/Kiri/f.png'
import meja_lipat_putih_samping_kiri_7 from '../../assets/meja_lipat/kaki_putih/Kiri/g.png'

export default class MejaLipatData{
  name = "Meja Lipat"
  description = "Meja Lipat Seraba Guna, cocok untuk dijadikan meja belajar, meja kerja lantai, meja laptop, dan lainnya. Dilengkapi slot handphone/tablet dengan bahan yang halus dan nyaman, serta desain yang simple. Meja anti air, kuat, dan mudah dibersihkan karena berbahan daur ulang plastik. Penggunaan mudah, dan bisa dilipat, sehingga mudah dibawa kemana-mana, mudah disimpan, dan hemat ruang.Siku tidak tajam, sehingga aman untuk anak-anak."
  size = "Ukuran : (PxLxT) : 60 x 40 x 28 cm</br>Berat: 1 Kg</br>Material : Daur Ulang Sampah plastik"
  price = "Rp185.000,00"

  arr = [
    {
      color: 'hitam',
      data: [
        [
          meja_lipat_hitam_belakang_1,
          meja_lipat_hitam_depan_1,
          meja_lipat_hitam_samping_kanan_1,
          meja_lipat_hitam_samping_kiri_1,
        ],
        [
          meja_lipat_hitam_belakang_2,
          meja_lipat_hitam_depan_2,
          meja_lipat_hitam_samping_kanan_2,
          meja_lipat_hitam_samping_kiri_2,
        ],
        [
          meja_lipat_hitam_belakang_3,
          meja_lipat_hitam_depan_3,
          meja_lipat_hitam_samping_kanan_3,
          meja_lipat_hitam_samping_kiri_3,
        ],
        [
          meja_lipat_hitam_belakang_4,
          meja_lipat_hitam_depan_4,
          meja_lipat_hitam_samping_kanan_4,
          meja_lipat_hitam_samping_kiri_4,
        ],
        [
          meja_lipat_hitam_belakang_5,
          meja_lipat_hitam_depan_5,
          meja_lipat_hitam_samping_kanan_5,
          meja_lipat_hitam_samping_kiri_5,
        ],
        [
          meja_lipat_hitam_belakang_6,
          meja_lipat_hitam_depan_6,
          meja_lipat_hitam_samping_kanan_6,
          meja_lipat_hitam_samping_kiri_6,
        ],
        [
          meja_lipat_hitam_belakang_7,
          meja_lipat_hitam_depan_7,
          meja_lipat_hitam_samping_kanan_7,
          meja_lipat_hitam_samping_kiri_7,
        ],
      ],
    },
    {
      color: 'putih',
      data: [
        [
          meja_lipat_putih_belakang_1,
          meja_lipat_putih_depan_1,
          meja_lipat_putih_samping_kanan_1,
          meja_lipat_putih_samping_kiri_1,
        ],
        [
          meja_lipat_putih_belakang_2,
          meja_lipat_putih_depan_2,
          meja_lipat_putih_samping_kanan_2,
          meja_lipat_putih_samping_kiri_2,
        ],
        [
          meja_lipat_putih_belakang_3,
          meja_lipat_putih_depan_3,
          meja_lipat_putih_samping_kanan_3,
          meja_lipat_putih_samping_kiri_3,
        ],
        [
          meja_lipat_putih_belakang_4,
          meja_lipat_putih_depan_4,
          meja_lipat_putih_samping_kanan_4,
          meja_lipat_putih_samping_kiri_4,
        ],
        [
          meja_lipat_putih_belakang_5,
          meja_lipat_putih_depan_5,
          meja_lipat_putih_samping_kanan_5,
          meja_lipat_putih_samping_kiri_5,
        ],
        [
          meja_lipat_putih_belakang_6,
          meja_lipat_putih_depan_6,
          meja_lipat_putih_samping_kanan_6,
          meja_lipat_putih_samping_kiri_6,
        ],
        [
          meja_lipat_putih_belakang_7,
          meja_lipat_putih_depan_7,
          meja_lipat_putih_samping_kanan_7,
          meja_lipat_putih_samping_kiri_7,
        ],
      ],
    }
  ]

}
