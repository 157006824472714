import nakas_belakang_1 from '../../assets/nakas/belakang/a.png'
import nakas_belakang_2 from '../../assets/nakas/belakang/b.png'
import nakas_belakang_3 from '../../assets/nakas/belakang/c.png'
import nakas_belakang_4 from '../../assets/nakas/belakang/d.png'
import nakas_belakang_5 from '../../assets/nakas/belakang/e.png'
import nakas_belakang_6 from '../../assets/nakas/belakang/f.png'
import nakas_belakang_7 from '../../assets/nakas/belakang/g.png'

import nakas_depan_1 from '../../assets/nakas/depan/a.png'
import nakas_depan_2 from '../../assets/nakas/depan/b.png'
import nakas_depan_3 from '../../assets/nakas/depan/c.png'
import nakas_depan_4 from '../../assets/nakas/depan/d.png'
import nakas_depan_5 from '../../assets/nakas/depan/e.png'
import nakas_depan_6 from '../../assets/nakas/depan/f.png'
import nakas_depan_7 from '../../assets/nakas/depan/g.png'

import nakas_samping_kanan_1 from '../../assets/nakas/kanan/a.png'
import nakas_samping_kanan_2 from '../../assets/nakas/kanan/b.png'
import nakas_samping_kanan_3 from '../../assets/nakas/kanan/c.png'
import nakas_samping_kanan_4 from '../../assets/nakas/kanan/d.png'
import nakas_samping_kanan_5 from '../../assets/nakas/kanan/e.png'
import nakas_samping_kanan_6 from '../../assets/nakas/kanan/f.png'
import nakas_samping_kanan_7 from '../../assets/nakas/kanan/g.png'

import nakas_samping_kiri_1 from '../../assets/nakas/kiri/a.png'
import nakas_samping_kiri_2 from '../../assets/nakas/kiri/b.png'
import nakas_samping_kiri_3 from '../../assets/nakas/kiri/c.png'
import nakas_samping_kiri_4 from '../../assets/nakas/kiri/d.png'
import nakas_samping_kiri_5 from '../../assets/nakas/kiri/e.png'
import nakas_samping_kiri_6 from '../../assets/nakas/kiri/f.png'
import nakas_samping_kiri_7 from '../../assets/nakas/kiri/g.png'

export default class NakasData{
  name = "Nakas Hairpin Minimalis"
  description = "Meja nakas didesain minimalis untuk mempercantik ruangan anda, seperti di kamar tidur, ruang tamu, ataupun ruang televisi. Di samping itu juga memberikan kesan elegan dan minimalis pada dekorasi rumah Anda. Menggunakan bahan papan daur ulang dari sampah plastik sehingga aman, tahan air, awet. Dikirim dalam bentuk flatpack sehingga anda dapat menghemat biaya pengiriman dan merakit sendiri sesuai instruksi yang telah tersedia. "
  size = "Ukuran (P x L x T) : 39 x 35 x 70 cm</br>Berat : 4 kg</br>Material : Papan Daur Ulang Sampah Plastik"
  price = "Rp1.000.000,00"

  arr = [
    [
      nakas_belakang_1,
      nakas_depan_1,
      nakas_samping_kanan_1,
      nakas_samping_kiri_1,
    ],
    [
      nakas_belakang_2,
      nakas_depan_2,
      nakas_samping_kanan_2,
      nakas_samping_kiri_2,
    ],
    [
      nakas_belakang_3,
      nakas_depan_3,
      nakas_samping_kanan_3,
      nakas_samping_kiri_3,
    ],
    [
      nakas_belakang_4,
      nakas_depan_4,
      nakas_samping_kanan_4,
      nakas_samping_kiri_4,
    ],
    [
      nakas_belakang_5,
      nakas_depan_5,
      nakas_samping_kanan_5,
      nakas_samping_kiri_5,
    ],
    [
      nakas_belakang_6,
      nakas_depan_6,
      nakas_samping_kanan_6,
      nakas_samping_kiri_6,
    ],
    [
      nakas_belakang_7,
      nakas_depan_7,
      nakas_samping_kanan_7,
      nakas_samping_kiri_7,
    ],
  ]

}
