import rak_putar_depan_1 from '../../assets/rak_putar/depan/a.png'
import rak_putar_depan_2 from '../../assets/rak_putar/depan/b.png'
import rak_putar_depan_3 from '../../assets/rak_putar/depan/c.png'
import rak_putar_depan_4 from '../../assets/rak_putar/depan/d.png'
import rak_putar_depan_5 from '../../assets/rak_putar/depan/e.png'
import rak_putar_depan_6 from '../../assets/rak_putar/depan/f.png'
import rak_putar_depan_7 from '../../assets/rak_putar/depan/g.png'

import rak_putar_samping_1 from '../../assets/rak_putar/samping/a.png'
import rak_putar_samping_2 from '../../assets/rak_putar/samping/b.png'
import rak_putar_samping_3 from '../../assets/rak_putar/samping/c.png'
import rak_putar_samping_4 from '../../assets/rak_putar/samping/d.png'
import rak_putar_samping_5 from '../../assets/rak_putar/samping/e.png'
import rak_putar_samping_6 from '../../assets/rak_putar/samping/f.png'
import rak_putar_samping_7 from '../../assets/rak_putar/samping/g.png'

export default class RakPutarData{
  name = "Rak Putar"
  description = "Rak Serbaguna ini memiliki kapasitas besar, mampu menahan beban cukup berat, dan bisa diputar. Cocok digunakan untuk merapikan bumbu dapur."
  size = "Ukuran : (DxT): 35cm x 8cm</br>Berat : 750 gr</br>Material : Daur Ulang Sampah plastik"
  price = "Rp165.000,00"

  arr = [
    [
      rak_putar_depan_1,
      rak_putar_samping_1,
    ],
    [
      rak_putar_depan_2,
      rak_putar_samping_2,
    ],
    [
      rak_putar_depan_3,
      rak_putar_samping_3,
    ],
    [
      rak_putar_depan_4,
      rak_putar_samping_4,
    ],
    [
      rak_putar_depan_5,
      rak_putar_samping_5,
    ],
    [
      rak_putar_depan_6,
      rak_putar_samping_6,
    ],
    [
      rak_putar_depan_7,
      rak_putar_samping_7,
    ],
  ]

}
