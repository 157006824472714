import color_1 from '../../assets/pilihan_warna/a.png'
import color_2 from '../../assets/pilihan_warna/b.png'
import color_3 from '../../assets/pilihan_warna/c.png'
import color_4 from '../../assets/pilihan_warna/d.png'
import color_5 from '../../assets/pilihan_warna/e.png'
import color_6 from '../../assets/pilihan_warna/f.png'
import color_7 from '../../assets/pilihan_warna/g.png'
import color_8 from '../../assets/pilihan_warna/h.png'
import color_9 from '../../assets/pilihan_warna/i.png'

export default class ColorData{
  arr = [
    {
      name: "Apricot",
      color: color_1,
    },
    {
      name: "Turquoise",
      color: color_2,
    },
    {
      name: "Ocean",
      color: color_3,
    },
    {
      name: "Midnight</br>Blue",
      color: color_4,
    },
    {
      name: "Caramel",
      color: color_5,
    },
    {
      name: "Azure</br>Blue",
      color: color_6,
    },
    {
      name: "Topaz",
      color: color_7,
    },
  ]

  arr_foot = [
    color_8,
    color_9,
  ]
}
