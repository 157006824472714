import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import HomePage from '../pages/home/index'

export default function HomeRoute() {
  return (
    <>
      <Route exact path="/" component={HomePage} />
    </>
  )
}