import BaseRouter from './routes/base'
import Navbar from './layout/navbar'
import Sidebar from './layout/sidebar'

import $ from 'jquery'
import 'bootstrap';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'summernote'
import "react-datepicker/dist/react-datepicker.css"

import {NotificationContainer, NotificationManager} from 'react-notifications'
import { useEffect } from 'react';

import PleaseWaitModal from './layout/modal/please_wait_modal'
import LogoutConfirmationModal from './layout/modal/logout_confirmation_modal'

export default function App() {
  useEffect(() => {
    window.$ = $
    window.jQuery = $
  }, [])

  return (
    <>
      {
        window.location.pathname != '/auth/login' ?
        <div className="h-100">
          <BaseRouter/>
        </div>
        :
        <div>
          <BaseRouter/>
        </div>
      }
      <NotificationContainer/>
    </>
  );
}
