import { useEffect, useState } from 'react';
import moment from 'moment'
import queryString from "query-string"

import Base from '../../utils/base'
import ColorData from '../../utils/collection/color_data'
import MejaLipatData from '../../utils/collection/meja_lipat_data'
import NakasData from '../../utils/collection/nakas_data'
import RakDapurData from '../../utils/collection/rak_dapur_data'
import RakMakeupData from '../../utils/collection/rak_makeup_data'
import RakPutarData from '../../utils/collection/rak_putar_data'
import PegboardData from '../../utils/collection/pegboard_data'

import arrow_left from '../../assets/panah_kiri.png'
import arrow_right from '../../assets/panah_kanan.png'

export default function Home(props) {
  var base = new Base()
  var color_data = new ColorData()
  var meja_lipat_data = new MejaLipatData()
  var nakas_data = new NakasData()
  var rak_dapur_data = new RakDapurData()
  var rak_makeup_data = new RakMakeupData()
  var rak_putar_data = new RakPutarData()
  var pegboard_data = new PegboardData()

  const [arr_color, set_arr_color] = useState([])
  const [arr_color_foot, set_arr_color_foot] = useState([])
  const [arr_size, set_arr_size] = useState([])
  const [arr, set_arr] = useState([])
  const [name, set_name] = useState('')
  const [description, set_description] = useState('')
  const [size, set_size] = useState('')
  const [price, set_price] = useState('')
  const [selected_color, set_selected_color] = useState(0)
  const [selected_color_foot, set_selected_color_foot] = useState(0)
  const [selected_image, set_selected_image] = useState('')
  const [query, set_query] = useState({})

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    set_arr_color(color_data.arr)
    if(query1.type == null){
      set_arr_color_foot(color_data.arr_foot)
      set_arr(meja_lipat_data.arr)
      set_name(meja_lipat_data.name)
      set_description(meja_lipat_data.description)
      set_size(meja_lipat_data.size)
      set_price(meja_lipat_data.price)
    }
    else{
      if(query1.type == 'meja_lipat'){
        set_arr_color_foot(color_data.arr_foot)
        set_arr(meja_lipat_data.arr)
        set_name(meja_lipat_data.name)
        set_description(meja_lipat_data.description)
        set_size(meja_lipat_data.size)
        set_price(meja_lipat_data.price)
      }
      else if(query1.type == 'nakas'){
        set_arr(nakas_data.arr)
        set_name(nakas_data.name)
        set_description(nakas_data.description)
        set_size(nakas_data.size)
        set_price(nakas_data.price)
      }
      else if(query1.type == 'rak_serbaguna'){
        set_arr(rak_dapur_data.arr)
        set_name(rak_dapur_data.name)
        set_description(rak_dapur_data.description)
        set_size(rak_dapur_data.size)
        set_price(rak_dapur_data.price)
        set_arr_size(rak_dapur_data.arr_size)
      }
      else if(query1.type == 'rak_makeup'){
        set_arr(rak_makeup_data.arr)
        set_name(rak_makeup_data.name)
        set_description(rak_makeup_data.description)
        set_size(rak_makeup_data.size)
        set_price(rak_makeup_data.price)
      }
      else if(query1.type == 'rak_putar'){
        set_arr(rak_putar_data.arr)
        set_name(rak_putar_data.name)
        set_description(rak_putar_data.description)
        set_size(rak_putar_data.size)
        set_price(rak_putar_data.price)
      }
      else if(query1.type == 'pegboard'){
        set_arr(pegboard_data.arr)
        set_name(pegboard_data.name)
        set_description(pegboard_data.description)
        set_size(pegboard_data.size)
        set_price(pegboard_data.price)
      }
    }
  }, [])

  useEffect(() => {
    if(arr.length > 0){
      if(query.type == null || (query.type != null && query.type == 'meja_lipat'))
        set_selected_image(arr[selected_color_foot]['data'][0][0])
      else
        set_selected_image(arr[0][0])
    }
  }, [arr])

  function on_selected_color(index){
    set_selected_color(index)
    if(query.type == null || (query.type != null && query.type == 'meja_lipat'))
      set_selected_image(arr[selected_color_foot]['data'][index][0])
    else
      set_selected_image(arr[index][0])
  }

  function on_selected_color_foot(index){
    set_selected_color_foot(index)
    set_selected_image(arr[index]['data'][selected_color][0])
  }

  function manage_navigate_image(type){
    var index = 0
    if(query.type == null || (query.type != null && query.type == 'meja_lipat')){
      var arr_temp = arr[selected_color_foot]['data'][selected_color]
      arr_temp.forEach((data, index1) => {
        if(data == selected_image){
          index = index1
          return
        }
      })
      var new_index = 0
      if(type === 'left')
        new_index = index > 0 ? index - 1 : arr_temp.length - 1
      else if(type === 'right')
        new_index = index < arr_temp.length - 1 ? index + 1 : 0
      set_selected_image(arr[selected_color_foot]['data'][selected_color][new_index])
    }
    else{
      var arr_temp = arr[selected_color]
      arr_temp.forEach((data, index1) => {
        if(data == selected_image){
          index = index1
          return
        }
      })
      var new_index = 0
      if(type === 'left')
        new_index = index > 0 ? index - 1 : arr_temp.length - 1
      else if(type === 'right')
        new_index = index < arr_temp.length - 1 ? index + 1 : 0
      set_selected_image(arr[selected_color][new_index])
    }
  }

  function on_left_click(){
    manage_navigate_image('left')
  }

  function on_right_click(){
    manage_navigate_image('right')
  }

  function on_selected_size(index){

  }

  return (
    <div className="row h-100 mx-0">
      <div className="col-12 col-lg-7 pl-0 pr-0 pr-lg-3">
        <div className="d-flex align-items-center h-100">
          <div className="p-5 w-100">
            <div className="d-flex justify-content-center align-items-center" style={{  }}>
              <div style={{ "cursor": "pointer" }} onClick={() => on_left_click()}>
                <img src={arrow_left} style={{ "height": "4rem", }}/>
              </div>
              <div style={{ "width": "100%", }} className="d-flex justify-content-center mx-3">
                <img src={selected_image} style={query.type != null && (query.type == 'nakas' || query.type == 'pegboard' || query.type == 'rak_putar') ? { "height": "15rem", } : { "width": "100%", }}/>
              </div>
              <div style={{ "cursor": "pointer" }} onClick={() => on_right_click()}>
                <img src={arrow_right} style={{ "height": "4rem", }}/>
              </div>
            </div>
            <div className="mt-3 row">
              {
                arr.length > 0 ?
                  query.type == null || (query.type != null && query.type == 'meja_lipat') ?
                  arr[selected_color_foot]['data'][selected_color].map((meja_lipat, index) => (
                    <div key={index} style={{"cursor": 'pointer'}} onClick={() => set_selected_image(meja_lipat)} className={"col-6 col-md border p-3 d-flex align-items-center" + (index > 0 ? ' ml-0 ml-md-2' : '')}>
                      <div>
                        <img src={meja_lipat} className="mb-2" style={{ "width": "100%" }}/>
                        <p className="m-0 text-center">
                          {
                            query.type != null && query.type == 'rak_putar' ?
                              index == 0 ? base.i18n.t('front') : (
                                index == 1 ? base.i18n.t('side') : ""
                              )
                            :
                              index == 0 ? base.i18n.t('rear') : (
                                index == 1 ? base.i18n.t('front') : (
                                  index == 2 ? base.i18n.t('right') : (
                                    index == 3 ? base.i18n.t('left') : ""
                                  )
                                )
                              )
                          }
                        </p>
                      </div>
                    </div>
                  ))
                  :
                  arr[selected_color].map((meja_lipat, index) => (
                    <div key={index} style={{"cursor": 'pointer'}} onClick={() => set_selected_image(meja_lipat)} className={"col-6 col-md border p-3 d-flex align-items-center justify-content-center" + (index > 0 ? ' ml-0 ml-md-2' : '')} >
                      <div className="text-center">
                        <img src={meja_lipat} className="mb-2" style={query.type != null && (query.type == 'nakas' || query.type == 'pegboard' || query.type == 'rak_putar') ? {"height": "10rem", } : {"width": "100%", }}/>
                        <p className="m-0 text-center">
                          {
                            query.type != null && query.type == 'rak_putar' ?
                              index == 0 ? base.i18n.t('front') : (
                                index == 1 ? base.i18n.t('side') : ""
                              )
                            :
                              index == 0 ? base.i18n.t('rear') : (
                                index == 1 ? base.i18n.t('front') : (
                                  index == 2 ? base.i18n.t('right') : (
                                    index == 3 ? base.i18n.t('left') : ""
                                  )
                                )
                              )
                          }
                        </p>
                      </div>
                    </div>
                  ))
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5 pr-0 pl-0 pl-lg-3">
        <div className="d-flex align-items-center h-100" style={{ 'backgroundColor': '#B1DFD4' }}>
          <div className="p-5 w-100 ">
            <div>
              <h4 style={{ 'color': '#606060' }}>{name}</h4>
              <p style={{ 'color': '#606060' }}>{price}</p>
              <p style={{ 'color': '#606060' }}>{description}</p>
              <p style={{ 'color': '#606060' }} dangerouslySetInnerHTML={{ __html: size }}></p>
            </div>
            <div className="mt-5">
              <p style={{ 'color': '#606060' }}>Pilih Warna:</p>
              <div className="">
              {
                arr_color.map((color, index) => (
                  <div className="d-inline-block text-center mx-2 mb-3 align-top" style={{ "cursor": "pointer", }} onClick={() => on_selected_color(index)}>
                    <div key={index} className={"rounded-circle d-inline-block" + (index > 0 ? '' : '')} style={{"width": "3rem", "height": "3rem", 'backgroundImage': "url(" + color.color + ")", "backgroundPosition": 'center', 'backgroundSize' : 'contain'}}>
                    </div>
                    <p className="m-0" style={{ 'color': '#606060' }} dangerouslySetInnerHTML={{ __html: color.name }}></p>
                  </div>
                ))
              }
              </div>
            </div>
            {
              arr_color_foot.length > 0 &&
              <div className="mt-5">
                <p style={{ 'color': '#606060' }}>Pilih Warna Kaki:</p>
                <div>
                {
                  arr_color_foot.map((color, index) => (
                    <div key={index} onClick={() => on_selected_color_foot(index)} className={"rounded-circle d-inline-block" + (index > 0 ? ' ml-2' : '')} style={{"width": "3rem", "height": "3rem", "cursor": "pointer", 'backgroundImage': "url(" + color + ")", "backgroundPosition": 'center', 'backgroundSize' : 'contain'}}>
                    </div>
                  ))
                }
                </div>
              </div>
            }
            {
              arr_size.length > 0 &&
              <div className="mt-5">
                <div className="row">
                {
                  arr_size.map((size, index) => (
                    <div key={index} onClick={() => on_selected_size(index)} className="col">
                      <button className="btn btn-light rounded-pill w-100" style={{ 'color': '#606060' }}>{size.name}</button>
                    </div>
                  ))
                }
                </div>
              </div>
            }
            <div className="mt-5">
              <div className="row">
                <div className="col">
                  <button className="btn btn-light rounded-pill w-100" style={{ 'color': '#606060' }}>Masukkan Keranjang</button>
                </div>
                <div className="col">
                  <button className="btn btn-light rounded-pill w-100" style={{ 'color': '#606060' }}>Beli Sekarang</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
