import rak_dapur_belakang_1 from '../../assets/rak_serbaguna/belakang/a.png'
import rak_dapur_belakang_2 from '../../assets/rak_serbaguna/belakang/b.png'
import rak_dapur_belakang_3 from '../../assets/rak_serbaguna/belakang/c.png'
import rak_dapur_belakang_4 from '../../assets/rak_serbaguna/belakang/d.png'
import rak_dapur_belakang_5 from '../../assets/rak_serbaguna/belakang/e.png'
import rak_dapur_belakang_6 from '../../assets/rak_serbaguna/belakang/f.png'
import rak_dapur_belakang_7 from '../../assets/rak_serbaguna/belakang/g.png'

import rak_dapur_depan_1 from '../../assets/rak_serbaguna/depan/a.png'
import rak_dapur_depan_2 from '../../assets/rak_serbaguna/depan/b.png'
import rak_dapur_depan_3 from '../../assets/rak_serbaguna/depan/c.png'
import rak_dapur_depan_4 from '../../assets/rak_serbaguna/depan/d.png'
import rak_dapur_depan_5 from '../../assets/rak_serbaguna/depan/e.png'
import rak_dapur_depan_6 from '../../assets/rak_serbaguna/depan/f.png'
import rak_dapur_depan_7 from '../../assets/rak_serbaguna/depan/g.png'

import rak_dapur_samping_kanan_1 from '../../assets/rak_serbaguna/kanan/a.png'
import rak_dapur_samping_kanan_2 from '../../assets/rak_serbaguna/kanan/b.png'
import rak_dapur_samping_kanan_3 from '../../assets/rak_serbaguna/kanan/c.png'
import rak_dapur_samping_kanan_4 from '../../assets/rak_serbaguna/kanan/d.png'
import rak_dapur_samping_kanan_5 from '../../assets/rak_serbaguna/kanan/e.png'
import rak_dapur_samping_kanan_6 from '../../assets/rak_serbaguna/kanan/f.png'
import rak_dapur_samping_kanan_7 from '../../assets/rak_serbaguna/kanan/g.png'

import rak_dapur_samping_kiri_1 from '../../assets/rak_serbaguna/kiri/a.png'
import rak_dapur_samping_kiri_2 from '../../assets/rak_serbaguna/kiri/b.png'
import rak_dapur_samping_kiri_3 from '../../assets/rak_serbaguna/kiri/c.png'
import rak_dapur_samping_kiri_4 from '../../assets/rak_serbaguna/kiri/d.png'
import rak_dapur_samping_kiri_5 from '../../assets/rak_serbaguna/kiri/e.png'
import rak_dapur_samping_kiri_6 from '../../assets/rak_serbaguna/kiri/f.png'
import rak_dapur_samping_kiri_7 from '../../assets/rak_serbaguna/kiri/g.png'

export default class RakDapurData{
  name = "Rak Gantung Serbaguna"
  description = "Rak Serbaguna ini siap pasang di dinding karena dilengkapi dengan lubang untuk mengantung dan paku. Dikirim dalam kondisi sudah terakit."
  size = "Ukuran :</br>Besar : (PxLxT) : 40 cm x 9 cm x 6,5 cm</br>Sedang : (PxLxT) : 30 cm x 9 cm x 6,5 cm</br>Kecil : (PxLxT) : 18 cm x 9 cm x 6,5 cm </br>Berat : 500 gram</br>Material : Daur Ulang Sampah plastik"
  price = "kecil : Rp30.000,00, sedang : Rp50.000,00, besar : Rp110.000,00"
  arr_size = [
    {
      id: "1",
      name: "Ukuran S",
    },
    {
      id: "2",
      name: "Ukuran M",
    },
    {
      id: "3",
      name: "Ukuran L",
    },
  ]

  arr = [
    [
      rak_dapur_belakang_1,
      rak_dapur_depan_1,
      rak_dapur_samping_kanan_1,
      rak_dapur_samping_kiri_1,
    ],
    [
      rak_dapur_belakang_2,
      rak_dapur_depan_2,
      rak_dapur_samping_kanan_2,
      rak_dapur_samping_kiri_2,
    ],
    [
      rak_dapur_belakang_3,
      rak_dapur_depan_3,
      rak_dapur_samping_kanan_3,
      rak_dapur_samping_kiri_3,
    ],
    [
      rak_dapur_belakang_4,
      rak_dapur_depan_4,
      rak_dapur_samping_kanan_4,
      rak_dapur_samping_kiri_4,
    ],
    [
      rak_dapur_belakang_5,
      rak_dapur_depan_5,
      rak_dapur_samping_kanan_5,
      rak_dapur_samping_kiri_5,
    ],
    [
      rak_dapur_belakang_6,
      rak_dapur_depan_6,
      rak_dapur_samping_kanan_6,
      rak_dapur_samping_kiri_6,
    ],
    [
      rak_dapur_belakang_7,
      rak_dapur_depan_7,
      rak_dapur_samping_kanan_7,
      rak_dapur_samping_kiri_7,
    ],
  ]

}
