import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import Login from '../pages/auth/login'

export default function Auth() {
  return (
    <>
      <Route exact path="/auth/login" component={Login} />
    </>
  )
}