import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import Home from './home'
import Auth from './auth'

export default function Base() {
  return (
    <BrowserRouter>
      <Auth/>
      <Home/>
    </BrowserRouter>
  )
}
